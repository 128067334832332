<template>
  <div class="lesson-item row" @click="goToLesson">
    <span class="lesson-number" >{{number}}</span>
    <span class="lesson-name">{{lesson.name}}</span>
    <span class="circle" :class="lesson.statusLesson">
        <div :class="statusIcon"></div>
    </span>
  </div>
</template>

<script>
import { Status } from '@/utils/Status.js'
export default {
  name: 'LessonItem',
  props:{
    lesson: Object, 
    number: Number,
  },
  computed: {
    unit(){
      return this.$store.state.currentUnit
    }, 
    statusIcon(){
      if(this.lesson.statusLesson === Status.NotStarted)
        return ''
        if(this.lesson.statusLesson === Status.InProgress)
          return 'play'
      return this.lesson.statusLesson === Status.Failed
        ? 'cross'
        : 'check'
    }
  },
  methods:{
    goToLesson(){
      if(this.$store.state.isPreviewModeActive)
        return 
      let overview = this.$t('overview')
      let route =this.lesson.name.toUpperCase().includes(overview.toUpperCase()) 
        ? {name: 'Overview', params: { unitId: this.lesson.id }} 
        : { name: 'Lesson', params: { lessonId: this.lesson.id, unitId: this.unit.id }} 
       this.$router.push(route);
       this.$store.commit('updateCurrentAssessmentSection', undefined)
    }
  }
}
</script>

<style lang="less" scoped>
.lessons .lesson-item:first-child{
  text-transform: uppercase;
}

.lesson-item{
  min-height: 48px;
  height: auto;
  color: rgba(0,0,0,0.6);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.25px;
  > span{
    min-height: 24px;
    align-self: center;
  }

}

.lesson-number{
  margin-left: 16.86px;
}

.lesson-name{
  margin-left: 25.3px;
  max-width: 480px;
  -ms-flex-item-align: center;
  align-self: center;
  white-space: normal;
  padding: 8px;
  height: auto;
}
.edit-button{
  margin-left: 25.3px;
  color: rgba(0,0,0,0.6);
  font-size: 12px;
}
.circle{
  margin-left: auto;
  margin-right: 27.75px;
}

.lesson-item:hover{
  background: #EAEBFF;
  cursor: pointer;
  
  span{
    color: #3A4DFD;
  }
  .circle{
    background: #EAEBFF;
    color: #EAEBFF;
  }
  span > div{
    position: absolute;
    top:25%;
    left: 20%;
    color: #fff;
    width: 10px;
    height: 10px;
    border-top: none;
    border-left: none;
    border-radius: none;
    margin: 0;
    border-bottom: solid 3px  rgba(0,0,0,0.38);
    border-right: solid 3px  rgba(0,0,0,0.38);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

}


</style>
