<template>
    <div class="biography"  v-if="biography">
        <div class="biography-container row">
            <section class="column">
                <BiographypPortrait :portrait="portrait"/>
                <complementaryText :audio="audio" :content="complementaryText"/>
            </section>
            <section class="column">
                <BiographyMainCard :mainCard="mainCard" :content="mainCard" />
                <div class="column">
                    <template v-if="hasMainIlustration">
                        <div  class="img-container">
                            <img :src="mainIlustrationImage" class="main-ilustration" v-on:load="getImageWidth">
                            <audio-toggle v-if="mainIlustrationAudioImage" :key="mainIlustrationAudioImage.id"
                                :white-background="true" :audio="mainIlustrationAudioImage"/>
                        </div>
                        <div class="img-caption" v-if="mainIlustrationShowCaptionImage" :style="imageStyles">
                                {{mainIlustrationCaptionImage}}
                        </div>
                    </template>
                    <template v-else>
                        <video-player v-if="videoUrl" :id="'biography-Video:'+biography.id"
                        :url="videoUrl" :width="624" :height="360" />
                    </template>
                </div>
            </section>
            <div class="biography-background"  :style="backgroundColor"></div>
        </div>
        <div class="lesson">
            <component v-for="paragraph in paragraphs" :is="paragraph.type"
            :key="paragraph.id" :content="paragraph" />
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/common/VideoPlayer.vue'
import BiographypPortrait from '@/components/Biography/BiographyPortrait.vue'
import BiographyMainCard from '@/components/Biography/BiographyMainCard.vue'
import Paragraph from '@/common/Paragraph.vue'
import BiographyService from '@/services/BiographyService.js'
import LessonParagraph from '@/components/Lesson/LessonParagraph.vue'
import LessonCard from '@/components/Lesson/LessonCard.vue'
import SectionTitle from '@/components/Lesson/SectionTitle.vue'
import EssentialQuestion from '@/common/EssentialQuestion.vue'
import LessonTextContent from '@/components/Lesson/LessonTextContent.vue'
import VideoContent from '@/components/Lesson/VideoContent.vue'
import InteractiveMap from '@/components/Lesson/InteractiveMap.vue'
import OnlyImages from '@/components/Lesson/OnlyImages.vue'
import AudioToggle from "@/common/AudioToggle.vue";
export default {
  name: 'Biography',
  components:{
    BiographypPortrait,
    BiographyMainCard,
    'complementaryText': Paragraph,
    BiographyService,
    'paragraph':    LessonParagraph,
    'card':   LessonCard,
    'section-title': SectionTitle,
    'text-content': LessonTextContent, 
    'only-images' : OnlyImages,
    VideoContent,
    EssentialQuestion,
    VideoPlayer, 
    InteractiveMap,
    AudioToggle
  },
  methods:{
      loadBiography(){
        this.$store.dispatch('loadBiography', this.$route.params.biographyId)
        this.$store.dispatch('loadCurrentUnit', this.$route.params.unitId)
      }
  },
  created(){   
      this.loadBiography()
  },
  computed:{
    unit(){
        return this.$store.state.currentUnit
    }, 
    biography(){
        return this.$store.state.currentBiography
    },
    paragraphs(){
        return this.biography.paragraphs || []
    },
    portrait(){
        return this.biography.portrait  || {}
    }, 
    mainCard(){
        return this.biography.mainCard  || {}
    }, 
    mainIlustration(){
        return this.biography.media  || {}
    }, 
    hasMainIlustration(){
        return !this.mainIlustration.image || !this.mainIlustration.image.name
            ? false : true
    }, 
    mainIlustrationImage(){
        return this.hasMainIlustration
         ? this.getImageUrl(this.mainIlustration.image.name)
         : ''
    },
    mainIlustrationAudioImage(){
        return this.mainIlustration.audioImage;
    },
    mainIlustrationCaptionImage(){
        return this.hasMainIlustration
         ? this.mainIlustration.captionImage
         : ''
    },
    mainIlustrationShowCaptionImage(){
        return this.mainIlustration && this.mainIlustration.captionImage.trim() != ''
    },
    videoUrl(){
        return this.mainIlustration.videoUrl
    },  
    complementaryText(){
        return this.biography.complementaryText
    },
    audio(){
        return this.biography.audioComplementaryText; 
    }, 
    backgroundColor(){
        return {
            backgroundColor:  this.biography.backgroundColor || '',
            opacity: this.biography.opacity / 100 || 1
          }
    }
  },
  watch:{
        '$route': function(){
            this.loadBiography()
        }
  },
    mounted(){
        this.startTrackingPage({ sectionName: "Biography" });
        this.$store.commit("setAnalyzedManagementSection", "Biography");

        window.scrollTo(0, 0);
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Biography" });
    }
}
</script>

<style lang="less">
.biography{
    margin: 0 auto;
    margin-top: 104px;
    width: 1280px;
    max-width: 1280px;
    flex-direction: column;
    position: relative;

    .biography-background{
        z-index: -4;
        position: fixed;
        top: 0;
        min-height: 100%;
        width: 100%;
    }

    .lesson{
        margin-top: 0;
        padding-bottom: 60px;
    }
    .lesson-container{ 
        height: auto;
        flex-direction: column;
    }
    .biography-container{
        margin: 0 auto;
        margin-top: 16px;
        justify-content: center;
        section{
            margin-top: 16px;
        }
        section:nth-child(2){
            margin-left: 16px;
            width: 622px;
            >.row{
                margin-top: 32px;
            }
            .main-ilustration, .video-player{
                margin: auto;
                width: auto;
                max-width: 624px;
                height: auto;
                max-height: 360px;
                margin-top: 32px;
            }
            .img-container{
                position: relative;
                margin: auto;
                .audio-toggle{
                    position: absolute;
                    right: 16px;
                    bottom: 16px;
                }
            }
            .img-caption{
                margin: auto;
            }
        }

        & /deep/ .paragraph > pre{
            width: 374px;
        }

        > .audio-toggle{
            position: absolute;
            top: 584px;
            left: 110px;

            background-color: #FFFFFF;
            border-radius: 50%;
        }
    }
}

</style>