<template>
  <div class="lessons-dashboard flex-container"  v-if="unit">
    <div class="unit-details flex-container"> 
        <img :src="image" class="unit-image"/>
        <span class="unit-name">
            {{unit.number}} {{unit.name}} {{unit.period}}</span>
        <div class="unit-information flex-container" @click="showTeacherPage">
          <img v-if="infoIcon" :src="infoIcon"> <span>{{$t('unitSkills')}}</span>
        </div>
        <div class="unit-description">
            <span>{{$t('unitDescription')}}</span>
            <audio-toggle v-if="unit.descriptionAudio" :audio="unit.descriptionAudio"/>
            <pre>{{unit.description}}</pre>
        </div>
    </div>
    <div class="lessons-board">
      <div class="board-title"><span>{{$t('lessons')}}</span></div>
      <div class="lessons" v-if="unit.lessons">
        <lesson-item v-for="(lesson, index) of unit.lessons" :key="index" :number="index"  :lesson="lesson"/>
      </div>
    </div>
  </div>
</template>

<script>
import LessonItem from '@/components/LessonItem.vue'
import AudioToggle from "@/common/AudioToggle.vue";

export default {
    name: 'LessonsDashboard',
    components: {
        LessonItem,
        AudioToggle
    },
    methods:{
        showTeacherPage(){
            if(this.$store.state.isPreviewModeActive)
                return 
            this.$router.push({ name: 'TeacherPageUnit', params: { 
                teacherPageId: this.unit.skillsId
              }, query:{
                title: this.unit.name
              }})
        }
    },
    mounted(){
        this.$store.commit('loadLesson', null)
        this.startTrackingPage({ sectionName: "Lessons Dashboard" });
        this.$store.commit("setAnalyzedManagementSection", "Lessons Dashboard");
    }, 
    computed: {
        unit(){
            return this.$store.state.currentUnit
        },
        image(){
            return this.getImageUrl(this.unit.imageName)
        }, 
        infoIcon(){
            return require('@/assets/icons/ic_info.svg')
        }
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Lessons Dashboard" });
    }
}
</script>

<style lang="less" scoped>
.lessons-dashboard{
  width: 1024px;
  margin: 0 auto;
  margin-top: calc(104px + 22px);
  justify-content: center;
}

.unit-details{
    flex-direction: column;

    .unit-image{
      height: auto;
      width: 100%;
      max-height: 140px;
      max-width: 300px;
      border-radius: 10px;
    }

    .unit-name{
      max-width: 304px;
      color: #0A1554;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 16px;
      margin-top: 7px;
      overflow: hidden;
      white-space: normal;
      span{
        margin-right: 8px;
      }
    }
}

.unit-information{
  
  margin-top: 35px;
  height: 16px;
  color: #3A4DFD;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 20px;
  cursor: pointer;
  img{
    height: 20px;
    width: 20px;
  }
  span{
    align-self: center;
    margin-left: 14px;
    text-transform: none;
  }
}


.unit-description{
  margin-top: 17px;
  width: 308px;
  overflow-y: auto;
  span{
    
      height: 24px;
      color: #3A4DFD;
      font-family: 'Montserrat', sans-serif;
      font-family: Montserrat;
      font-size: 19.64px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 24px;
  }

  .audio-toggle{
    margin-left: 10px;
  }
  
  pre{
    color: rgba(0,0,0,0.60);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 20px;
  }
}

.lessons-board{
  min-height: 300px;
  margin-left: 21px;
  width: 624px;
  position: relative;
  box-shadow: 0 4px 20px 0 rgba(10,21,84,0.20);
  padding-bottom: 34px;
  
  .board-title{
    background: #0A1554;
    letter-spacing: 0.25px;
    height: 39.46px;
    color: #FFFFFF;
    font-size: 23.92px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    display: grid;
    text-transform: capitalize;
      span{
        margin: auto;
      }
  }
  .lessons{
    width: inherit;
  }



}

</style>

