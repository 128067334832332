import VocabularyBox from '@/components/Lesson/VocabularyBox.vue'
import Modal from '@/common/Modal.vue'

export default{
    components:{
        VocabularyBox, 
        Modal
    }, 
    props:{
        content : Object
    },
    data(){
        return{
            selectedWord: '',
            showModal: false, 
            modalType: '',
            showModalCancelButton: false, 
            tagForClickableWord: '<strong class="clickable-link">',
            closingtagForClickableWord: '</strong>'
        }
    },
    computed:{
        textContent(){
            let text = this.content.content 
            if(!text) return ''

            text = this.insertLinksInParagraph(this.biographies, text, true)
            return  this.insertLinksInParagraph(this.vocabulary, text)
        }, 
        vocabulary(){
            return this.$store.state.currentUnit
            ? this.$store.state.currentUnit.vocabularies
            : []
        },
        biographies(){
            return this.$store.getters.biographies || []
        },
    },
    methods:{
        GoToLink(e){
            if(this.$store.state.isPreviewModeActive)
                return
            if(e.target.className != "clickable-link")
                return

            let wordClicked = e.target.innerText.toLowerCase().trim()

            let biographies = this.$store.getters.biographies || []
            let biography = biographies.filter(e => e.name)
                .find(e => e.name.trim().toLowerCase() === wordClicked)
            biography 
                ? this.GoToBiography(biography)
                : this.showVocabularyBox(wordClicked)
        },
        GoToBiography(biography){
            if(biography.route) 
                this.$router.push(biography.route)
        },
        showVocabularyBox(wordClicked){
            let vocabulary = this.$store.state.currentUnit
            ? this.$store.state.currentUnit.vocabularies
            : []

            let word = vocabulary.find(e => e.name.trim().toLowerCase() === wordClicked)
            if(!word)
                return;

            this.selectedWord = word
            this.modalType = "vocabularyModal";
            this.showModalCancelButton = false;
            this.showModal = true
        },
        hideVocabularyBox(){
            this.showModal = false
            this.showModalCancelButton = true; 
            this.modalType = ""; 
            this.selectedWord = ''
        },
        insertLinksInParagraph(items, paragraph, global = false){
            var name;
            items.forEach(e =>{
                if(e.name){
                    name = e.name.trim()
                    
                    paragraph = paragraph.replace(
                        new RegExp(`(?<=\\W)(${name})(?=\\W)`, `${global ? 'ig': 'i'}`), (match) =>{
                            return `${this.tagForClickableWord}${match}${this.closingtagForClickableWord}`
                        })
                        
                }
            })
            return paragraph 
        }
    }
}