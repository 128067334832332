<template>
    <div class="portrait column">
        <div class="portrait-header">
            <img src="@/assets/Bio_Banner.png">
            <span class="centered"> 
                {{biographyBanner}}
            </span>
        </div>
        <img :src="imageSrc" alt="">
        <disclaimer v-if="portrait.disclaimer && portrait.disclaimer[locale]" :disclaimer="portrait.disclaimer[locale]"/>
        <div class="portrait-footer row" v-if="portrait.caption">
            <span v-tooltip="portrait.caption[locale] || ''">{{portrait.caption[locale]}}</span>
        </div>
        <audio-toggle v-if="audio" :key="audio.id" :white-background="true" :audio="audio"/>
    </div>    
</template>
<script>
import Disclaimer from '@/common/Disclaimer.vue'
import AudioToggle from "@/common/AudioToggle.vue";

export default {
    name: 'BiographyPortrait', 
    props:['portrait'],
    components:{
        Disclaimer,
        AudioToggle
    },
    computed:{
        imageSrc(){
            return this.getImageUrl(this.portrait.name || null)
        },
        biographyBanner(){
            return this.$t('biography')
        },
        audio(){
            return this.portrait.audio;
        }
    }
}
</script>
<style lang="less" scoped>
    .portrait{
        position: relative;
        height: auto;
        min-height: 520px;
        max-width: 374px;
        width: 374px;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,0.2);

        .portrait-header{
            position: relative;
             text-transform: capitalize;
            max-height: 56px;
            width: 374px;
            background-color: #0A1554;
            img{
                height: auto;
                width: 100%;
                max-height: 56px;
                max-width: 374px;
            }

            span{
                color: #fff;
                font-size: 19.64px;
                font-weight: bold;
                letter-spacing: 0.25px;
                line-height: 24px;
            }
        }
        .portrait-footer{
            height: 48px;
            width: 374px;
            background-color: #EAEBFF;
            span{
                margin: auto;
                color: #3A4DFD;
                font-family: 'Roboto', sans-serif;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 16px;
                text-transform: uppercase;
            }
        }
        img{
            height: 416px;
            width: 100%;
        }

        .audio-toggle{
            position: absolute;
            top: 442px;
            left: 344px;
        }
    }
</style>



