import { render, staticRenderFns } from "./Paragraph.vue?vue&type=template&id=236bbc08&scoped=true&"
import script from "./Paragraph.vue?vue&type=script&lang=js&"
export * from "./Paragraph.vue?vue&type=script&lang=js&"
import style0 from "./Paragraph.vue?vue&type=style&index=0&id=236bbc08&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236bbc08",
  null
  
)

component.options.__file = "Paragraph.vue"
export default component.exports