<template>
    <div class="paragraph row">
        <p>{{content}}</p>
        <AudioToggle v-if="audio" :audio="audio"/>
    </div>    
</template>
<script>
import AudioToggle from '@/common/AudioToggle.vue'
export default {
    name: 'Paragraph', 
    components:{
        AudioToggle
    },
    props:['content', 'audio'], 
}
</script>
<style lang="less" scoped>
    .paragraph
    {
        p{    
            width: 324px;
            color: #000;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            letter-spacing: 0.4px;
            line-height: 22px;
            padding: 8px;
        }
        .audio-toggle{
            margin-left: auto;
            margin-right: 8px;
            margin-top: 8px;
        }
    }
</style>
